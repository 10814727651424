import { Spin, Button, Avatar } from "antd"
import { apiActivity } from "api"
import useComponentVisible from "lib/click-outside"
import { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
// import { Approval_Status } from "lib/Const"
// import dayjs from "dayjs"
import { getDateSetting } from "lib/function"
import "./index.scss"

export default function ActivityLog() {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const listInnerRef = useRef()
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const __pagination = useRef({
    page_num: 1,
    page_size: 10,
    has_next: false
  })

  const fetch = async () => {
    setLoading(true)
    try {
      const { data, has_next } = await apiActivity.gets(__pagination.current)
      __pagination.current.has_next = has_next
      setRows((prev) => [...prev, ...data])
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      const isNearBottom = scrollTop + clientHeight >= scrollHeight

      if (isNearBottom && __pagination.current.has_next) {
        // DO SOMETHING HERE
        __pagination.current.page_num = __pagination.current.page_num + 1
        fetch()
      }
    }
  }

  useEffect(() => {
    const listInnerElement = listInnerRef.current

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll)

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible])

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="w-10 cursor-pointer flex justify-center items-center">
      <div
        className="hover:bg-[#E3F1F8] hover:rounded-[8px]"
        style={{
          backgroundColor: isComponentVisible && "#E3F1F8",
          borderRadius: isComponentVisible && 8
        }}
        onClick={() => setIsComponentVisible(true)}
      >
        {icActivity}
      </div>
      <div ref={ref}>
        {isComponentVisible && (
          <div className="fixed right-10 w-[460px] top-16 z-20 shadow bg-white py-4 rounded">
            <Spin spinning={loading}>
              <div className="border-b border-[#E6EDF2] pb-2 px-4">
                <div className="text-[20px] text-[#0E2026] font-[700]">Activity Logs</div>
              </div>

              <div className="mt-2 px-4 h-[450px] overflow-y-auto" ref={listInnerRef}>
                {rows?.length > 0 ? (
                  rows?.map((i, key) => {
                    return (
                      <div key={key} className="p-2 mb-3 border-b border-[#E6EDF2]">
                        <div className="flex gap-4">
                          <div>
                            <Avatar icon={<div>{logo}</div>} />
                          </div>
                          <div className="w-full grid grid-cols-8 gap-4">
                            <div className="col-span-5">
                              <div className="text-[18px] text-[#0E2026] font-[700] line-clamp-1">
                                <span className="capitalize">{i?.action ? i?.action : "Do"} </span>
                                {i?.object_code
                                  ? i?.object_code?.split("_").join(" ")
                                  : "something"}
                              </div>
                              <div className="text-[16px] font-[400] text-[#646970] line-clamp-2">
                                {i?.description ? i?.description : "No description"}
                              </div>
                            </div>
                            <div className="col-span-3 flex justify-end gap-2 items-start">
                              <div className="text-[14px] font-[400] text-[#646970]">
                                {getDateSetting(i?.created_at)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="flex justify-center items-center">No data</div>
                )}
              </div>
              <div className="mx-3">
                <Link to="/activity-logs" onClick={() => setIsComponentVisible(false)}>
                  <Button
                    className="w-full !bg-[#E3F1F8] !text-primary !font-medium"
                    type="primary"
                    size="large"
                  >
                    See activity logs
                  </Button>
                </Link>
              </div>
            </Spin>
          </div>
        )}
      </div>
    </div>
  )
}

const icActivity = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9842_240551)">
      <path
        d="M20 8C13.3732 8 8 13.3732 8 20C8 26.6268 13.3732 32 20 32C26.6268 32 32 26.6268 32 20C32 13.3732 26.6268 8 20 8ZM20 29.9643C14.4982 29.9643 10.0357 25.5018 10.0357 20C10.0357 14.4982 14.4982 10.0357 20 10.0357C25.5018 10.0357 29.9643 14.4982 29.9643 20C29.9643 25.5018 25.5018 29.9643 20 29.9643Z"
        fill="#4DABD1"
      />
      <path
        d="M24.6797 23.3886L20.86 20.627V13.9975C20.86 13.8796 20.7636 13.7832 20.6457 13.7832H19.3574C19.2395 13.7832 19.1431 13.8796 19.1431 13.9975V21.3743C19.1431 21.4439 19.1752 21.5082 19.2315 21.5484L23.6618 24.7787C23.7582 24.8484 23.8922 24.827 23.9618 24.7332L24.7279 23.6886C24.7975 23.5895 24.7761 23.4555 24.6797 23.3886Z"
        fill="#4DABD1"
      />
    </g>
    <defs>
      <clipPath id="clip0_9842_240551">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
)

const logo = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#8DC9E1" />
    <path
      d="M19.9289 11.5315V10.4601C19.9289 10.3619 19.8485 10.2815 19.7503 10.2815H11.1789C11.0806 10.2815 11.0003 10.3619 11.0003 10.4601V11.5315C11.0003 11.6297 11.0806 11.7101 11.1789 11.7101H19.7503C19.8485 11.7101 19.9289 11.6297 19.9289 11.5315ZM11.1789 13.4958C11.0806 13.4958 11.0003 13.5762 11.0003 13.6744V14.7458C11.0003 14.844 11.0806 14.9244 11.1789 14.9244H15.286C15.3842 14.9244 15.4646 14.844 15.4646 14.7458V13.6744C15.4646 13.5762 15.3842 13.4958 15.286 13.4958H11.1789ZM19.5717 16.0851C16.9088 16.0851 14.7503 18.2436 14.7503 20.9065C14.7503 23.5695 16.9088 25.728 19.5717 25.728C22.2347 25.728 24.3931 23.5695 24.3931 20.9065C24.3931 18.2436 22.2347 16.0851 19.5717 16.0851ZM21.9713 23.3061C21.3306 23.9467 20.478 24.2994 19.5717 24.2994C18.6655 24.2994 17.8128 23.9467 17.1722 23.3061C16.5315 22.6655 16.1789 21.8128 16.1789 20.9065C16.1789 20.0003 16.5315 19.1476 17.1722 18.507C17.8128 17.8664 18.6655 17.5137 19.5717 17.5137C20.478 17.5137 21.3306 17.8664 21.9713 18.507C22.6119 19.1476 22.9646 20.0003 22.9646 20.9065C22.9646 21.8128 22.6119 22.6655 21.9713 23.3061ZM21.5583 19.2101H20.5695C20.5114 19.2101 20.4579 19.2369 20.4244 19.2838L19.007 21.2436L18.4914 20.5315C18.4748 20.5085 18.453 20.4898 18.4278 20.477C18.4025 20.4642 18.3746 20.4576 18.3463 20.4579H17.3619C17.2168 20.4579 17.132 20.623 17.2168 20.7414L18.8641 23.0204C18.9355 23.1186 19.0806 23.1186 19.1521 23.0204L21.7012 19.4936C21.7882 19.3753 21.7034 19.2101 21.5583 19.2101ZM14.3931 23.5851H9.21456V7.87081H21.7146V15.5494C21.7146 15.6476 21.7949 15.728 21.8931 15.728H23.1431C23.2414 15.728 23.3217 15.6476 23.3217 15.5494V6.97796C23.3217 6.58287 23.0025 6.26367 22.6074 6.26367H8.32171C7.92662 6.26367 7.60742 6.58287 7.60742 6.97796V24.478C7.60742 24.873 7.92662 25.1922 8.32171 25.1922H14.3931C14.4914 25.1922 14.5717 25.1119 14.5717 25.0137V23.7637C14.5717 23.6655 14.4914 23.5851 14.3931 23.5851Z"
      fill="#E3F1F8"
    />
  </svg>
)
