import { Button, Modal, notification, Input } from "antd"
import { apiApproval } from "api"
import { useEffect, useState } from "react"
import TagApprovalStatus from "components/Tag/TagApprovalStatus"
import { getBaseDataLocal } from "lib/localstorage"
import { getDateSetting, getActiveAction } from "lib/function"
// import dayjs from "dayjs"

export default function ModalUpdateApproval({ open, onClose, onConfirm, item, noti_id }) {
  const { user } = getBaseDataLocal()
  const [loading, setLoading] = useState(false)
  const [approvalInfo, setApprovalInfo] = useState([])
  const [notes, setNotes] = useState(null)

  // Function to handle approval update (approve/reject)
  async function handleOk(v) {
    try {
      setLoading(true)
      await apiApproval.updateStatus(item?.id, {
        approved: v,
        notes: notes,
        approval_id: item?.id,
        noti_id: noti_id
      })
      if (v) {
        notification.success({ description: "Request has been approved successfully." })
      } else {
        notification.success({ description: "Request has been rejected successfully." })
      }
      onConfirm()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  function disabledButton(item) {
    if (item?.process?.approvers) {
      let findApprover = item?.process?.approvers.find((v) => v.id === user?.id)
      if (!findApprover) {
        return true
      }
    }
    return item?.status === 1 || item?.status === 2
  }

  useEffect(() => {
    if (open && item) {
      const { description, status, data, approval_setting } = item
      setApprovalInfo([
        { name: "Module", value: approval_setting?.module?.name },
        { name: "Description", value: description },
        { name: "Status", value: status, isStatus: true },
        { name: "Requester", value: data?.user?.email },
        {
          name: "Date and time",
          value: getDateSetting(item?.updated_at ? item?.updated_at : item?.created_at)
        }
      ])
      if (status === 0) {
        setNotes(null)
      } else {
        setNotes(item?.notes)
      }
    }
  }, [open, item])

  // JSX rendering for the component
  return (
    <Modal
      title="Approve Request"
      open={open}
      onCancel={onClose}
      footer={
        <div className="flex justify-end gap-4 border-t pt-5">
          {getActiveAction("reject_approval") && (
            <Button
              type="primary"
              danger
              loading={loading}
              onClick={() => handleOk(false)}
              disabled={disabledButton(item)}
            >
              Reject
            </Button>
          )}
          {getActiveAction("approve_approval") && (
            <Button
              type="primary"
              loading={loading}
              onClick={() => handleOk(true)}
              disabled={disabledButton(item)}
            >
              Approve
            </Button>
          )}
        </div>
      }
    >
      <div className="my-6">
        <div>
          {approvalInfo.map((v, index) => (
            <ColumnInfo v={v} index={index} length={approvalInfo.length} />
          ))}
        </div>
        <div className="mt-4">
          <div className="mb-1 text-[#646970]">Notes</div>
          <Input.TextArea
            value={notes}
            placeholder="Notes your reason"
            rows={4}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  )
}

const ColumnInfo = (item) => {
  const { v, index, length } = item
  return (
    <div className="w-full" key={index}>
      <div className="flex w-full gap-4">
        <div className="w-1/4">{v?.name}</div>
        {typeof v?.value === "string" && <div className="w-3/4">{v?.value}</div>}
        {v?.isStatus && <TagApprovalStatus status={v?.value} />}
      </div>
      {index < length - 1 && <div className="w-full my-2 h-[1px] bg-[#E6EDF2]"></div>}
    </div>
  )
}
