import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant"

function checkExistedDomain(params) {
  return Client.get(`${version}/public/domains/sub-domain-existed/`, { params })
}

function getSchools(params) {
  return Client.get(`${resource}/official-schools/all/`, { params })
}

function getSchoolGrades(params) {
  return Client.get(`${resource}/school-grades/all/`, { params })
}

function getSchoolClasses(params) {
  return Client.get(`${resource}/school-classes/all/`, { params })
}

function getRegions(params) {
  return Client.get(`${resource}/regions/all/`, { params })
}

function getAreas(params) {
  return Client.get(`${resource}/areas/all/`, { params })
}

const api = {
  checkExistedDomain,
  getSchools,
  getSchoolGrades,
  getSchoolClasses,
  getRegions,
  getAreas
}
export default api
