import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/schools"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function exportList(params) {
  return Client.post(`${resource}/export-csv/`, { params })
}

function getRooms(school_id, params) {
  return Client.get(`${resource}/${school_id}/rooms/`, { params })
}
function getRoom(school_id, id) {
  return Client.get(`${resource}/${school_id}/rooms/${id}/`)
}
function createRoom(school_id, data) {
  return Client.post(`${resource}/${school_id}/rooms/`, data)
}
function updateRoom(school_id, id, data) {
  return Client.put(`${resource}/${school_id}/rooms/${id}/`, data)
}
function removeRoom(school_id, id) {
  return Client.delete(`${resource}/${school_id}/rooms/${id}/`)
}

function getLessons(school_id, params) {
  return Client.get(`${resource}/${school_id}/lesson-schedules/`, { params })
}
function getLesson(school_id, id) {
  return Client.get(`${resource}/${school_id}/lesson-schedules/${id}/`)
}
function createLesson(school_id, data) {
  return Client.post(`${resource}/${school_id}/lesson-schedules/`, data)
}
function updateLesson(school_id, id, data) {
  return Client.put(`${resource}/${school_id}/lesson-schedules/${id}/`, data)
}
function removeLesson(school_id, id) {
  return Client.delete(`${resource}/${school_id}/lesson-schedules/${id}/`)
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  exportList,
  //Room
  getRooms,
  getRoom,
  createRoom,
  updateRoom,
  removeRoom,
  //Lesson
  getLessons,
  getLesson,
  createLesson,
  updateLesson,
  removeLesson
}
export default api
