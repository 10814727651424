import { Button, Dropdown, Image } from "antd"
// import { useEffect, useState } from "react"
import { getBaseDataLocal } from "lib/localstorage"
import { renderTime } from "lib/function"
import dayjs from "dayjs"
// import notiApproval from "assets/images/notiApproval.png"

export default function BoxApproval({ item, onConfirm, isMyNotification, onMarkUnread, onRemove }) {
  const { user } = getBaseDataLocal()
  const { data, title, body, _id, is_read, loading, created_at } = item

  const handleOk = async (e, v) => {
    e.stopPropagation()
    onConfirm(data?.id, v, _id, is_read)
  }

  function disabledButton() {
    if (data?.process?.approvers) {
      let findApprover = data?.process?.approvers.find((v) => v.id === user?.id)
      if (!findApprover) {
        return true
      }
    }
    return data?.status === 1 || data?.status === 2
  }

  return (
    <div>
      <div className="flex gap-4">
        <div>{/* <Image width={52} height={52} preview={false} src={notiApproval} /> */}</div>
        <div className="w-full grid grid-cols-7 gap-4">
          <div className="col-span-6">
            <div className="text-[16px] font-bold line-clamp-1">{title}</div>
            <div className="text-[14px] text-[#646970] line-clamp-2">{body}</div>
          </div>
          <div className="col-span-1">
            <div className="flex justify-end gap-2 items-center">
              <div className="text-xs">{renderTime(dayjs(created_at))}</div>
              {!is_read && <div>{icActive}</div>}
              {isMyNotification && (
                <Dropdown
                  overlay={
                    <div className="shadow_antd w-[240px]">
                      <Button
                        className="w-full !text-start !flex !items-center"
                        type="text"
                        icon={icTick}
                        onClick={(e) => {
                          e.stopPropagation()
                          onMarkUnread(_id)
                        }}
                      >
                        Mark as unread
                      </Button>
                      <Button
                        className="w-full !text-start !flex !items-center"
                        type="text"
                        icon={icClose}
                        onClick={(e) => {
                          e.stopPropagation()
                          onRemove(_id)
                        }}
                      >
                        Remove this notification
                      </Button>
                    </div>
                  }
                >
                  <Button className="!p-[4px]" type="text" onClick={(e) => e.stopPropagation()}>
                    {icMore}
                  </Button>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* {data?.status === Approval_Status.in_process && !is_read && ( */}
      <div className="flex gap-4 justify-start mt-3">
        <Button
          type="primary"
          onClick={(e) => handleOk(e, true)}
          loading={loading}
          disabled={disabledButton()}
        >
          Approve
        </Button>
        <Button
          type="primary"
          danger
          onClick={(e) => handleOk(e, false)}
          loading={loading}
          disabled={disabledButton()}
        >
          Reject
        </Button>
      </div>
      {/* )} */}
    </div>
  )
}

const icActive = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.25" y="1.25" width="11.5" height="11.5" rx="5.75" fill="#4DABD1" />
    <rect x="1.25" y="1.25" width="11.5" height="11.5" rx="5.75" stroke="white" strokeWidth="1.5" />
  </svg>
)
const icMore = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2.98438C7 3.24959 7.10536 3.50395 7.29289 3.69148C7.48043 3.87902 7.73478 3.98438 8 3.98438C8.26522 3.98438 8.51957 3.87902 8.70711 3.69148C8.89464 3.50395 9 3.24959 9 2.98438C9 2.71916 8.89464 2.4648 8.70711 2.27727C8.51957 2.08973 8.26522 1.98438 8 1.98438C7.73478 1.98438 7.48043 2.08973 7.29289 2.27727C7.10536 2.4648 7 2.71916 7 2.98438ZM7 7.98438C7 8.24959 7.10536 8.50395 7.29289 8.69148C7.48043 8.87902 7.73478 8.98438 8 8.98438C8.26522 8.98438 8.51957 8.87902 8.70711 8.69148C8.89464 8.50395 9 8.24959 9 7.98438C9 7.71916 8.89464 7.4648 8.70711 7.27727C8.51957 7.08973 8.26522 6.98438 8 6.98438C7.73478 6.98438 7.48043 7.08973 7.29289 7.27727C7.10536 7.4648 7 7.71916 7 7.98438ZM7 12.9844C7 13.2496 7.10536 13.5039 7.29289 13.6915C7.48043 13.879 7.73478 13.9844 8 13.9844C8.26522 13.9844 8.51957 13.879 8.70711 13.6915C8.89464 13.5039 9 13.2496 9 12.9844C9 12.7192 8.89464 12.4648 8.70711 12.2773C8.51957 12.0897 8.26522 11.9844 8 11.9844C7.73478 11.9844 7.48043 12.0897 7.29289 12.2773C7.10536 12.4648 7 12.7192 7 12.9844Z"
      fill="#646970"
    />
  </svg>
)
const icTick = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9296 2.8125H17.3694C17.1506 2.8125 16.943 2.91295 16.8091 3.08482L7.60597 14.7433L3.19302 9.15179C3.12627 9.06703 3.04118 8.9985 2.94414 8.95133C2.8471 8.90417 2.74065 8.8796 2.63276 8.87946H1.07249C0.922934 8.87946 0.840344 9.05134 0.931862 9.16741L7.0457 16.9129C7.33142 17.2746 7.88052 17.2746 8.16847 16.9129L19.0703 3.09821C19.1618 2.98438 19.0792 2.8125 18.9296 2.8125V2.8125Z"
      fill="#646970"
    />
  </svg>
)
const icClose = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1562 9.99895L17.0155 3.01458C17.1137 2.89851 17.0312 2.72217 16.8794 2.72217H15.0981C14.9932 2.72217 14.8928 2.76904 14.8236 2.8494L9.99097 8.61056L5.15838 2.8494C5.09142 2.76904 4.99097 2.72217 4.88383 2.72217H3.10258C2.95079 2.72217 2.8682 2.89851 2.96642 3.01458L8.82579 9.99895L2.96642 16.9833C2.94442 17.0092 2.9303 17.0408 2.92575 17.0745C2.9212 17.1082 2.9264 17.1424 2.94073 17.1732C2.95507 17.204 2.97794 17.23 3.00663 17.2482C3.03532 17.2664 3.06862 17.2759 3.10258 17.2757H4.88383C4.98874 17.2757 5.08919 17.2289 5.15838 17.1485L9.99097 11.3873L14.8236 17.1485C14.8905 17.2289 14.991 17.2757 15.0981 17.2757H16.8794C17.0312 17.2757 17.1137 17.0994 17.0155 16.9833L11.1562 9.99895Z"
      fill="#646970"
    />
  </svg>
)
